import { makeStyles, TableCell, TableRow, Tooltip } from '@material-ui/core';
import format from 'date-fns/format';
import React, { FunctionComponent } from 'react';
import { DailyFlightMealOrder, EmployeeDuty, MealOrder } from './types';

const useStyles = makeStyles(() => ({
    detailRow: { display: 'flex', justifyContent: 'space-between' },
}));

const getEmployeeDuty = (
    employeeDuty: EmployeeDuty,
): { tooltip: string; title: string } => {
    switch (employeeDuty) {
        case EmployeeDuty.CabinCrew:
            return {
                tooltip: 'Cabin crew',
                title: 'JCC',
            };
        case EmployeeDuty.Captain:
            return {
                tooltip: 'Captain',
                title: 'CP',
            };
        case EmployeeDuty.CabinSupervisor:
            return {
                tooltip: 'Cabin supervisor',
                title: 'CS',
            };
        case EmployeeDuty.FirstOfficer:
            return {
                tooltip: 'First officer',
                title: 'FO',
            };
        default:
            throw new Error('Unhandled employee duty');
    }
};

const Detail: FunctionComponent<{ detail: MealOrder }> = ({ detail }) => {
    const classes = useStyles();
    const { tooltip, title } = getEmployeeDuty(detail.employeeDuty);

    return (
        <div className={classes.detailRow}>
            <span>{detail.mealType}</span>{' '}
            <Tooltip title={tooltip}>
                <span className={classes.detailRow}>{title}</span>
            </Tooltip>{' '}
            <span className={classes.detailRow}>{detail.employeeCode}</span>
            <span>x {detail.amount}</span>
        </div>
    );
};

const OrderRow: FunctionComponent<{ row: DailyFlightMealOrder }> = ({ row }) => {
    const { flightNumber, route, departure, arrival, planeType, meals } = row;
    const dateFormat = 'dd.MM.yyyy HH:mm';

    const mealsAmount = meals
        .map(meal => meal.amount)
        .reduce((prev, curr) => prev + curr);

    return (
        <TableRow>
            <TableCell>{flightNumber}</TableCell>
            <TableCell>
                {route.departure.iata} - {route.arrival.iata}
            </TableCell>
            <TableCell>{format(Date.parse(departure), dateFormat)}</TableCell>
            <TableCell>{format(Date.parse(arrival), dateFormat)}</TableCell>
            <TableCell>{planeType}</TableCell>
            <TableCell>{mealsAmount} pcs</TableCell>
            <TableCell>
                {meals.map((detail, index) => (
                    <Detail key={`${index}-${detail.employeeCode}`} detail={detail} />
                ))}
            </TableCell>
        </TableRow>
    );
};

export default OrderRow;
