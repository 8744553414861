import { FullScreenLoader } from '@get-e/react-components';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import AuthenticatedUserProvider from './AuthenticatedUserProvider';
import createRoutes from './createRoutes';
import Page from './layouts/Page';
import ForgotPassword from './pages/ForgotPassword';
import SignIn from './pages/SignIn';

const InitialRoute: FunctionComponent = () => {
    const history = useHistory();

    useEffect(() => {
        history.push('/sign-in');
    }, [history]);

    return <FullScreenLoader />;
};

const Routes: FunctionComponent = () => {
    const routes = useMemo(() => {
        const routesConfigs = createRoutes();

        // eslint-disable-next-line @typescript-eslint/naming-convention
        return routesConfigs.map(({ path, Component }) => (
            <Route exact path={path} key={path}>
                <AuthenticatedUserProvider>
                    <Component />
                </AuthenticatedUserProvider>
            </Route>
        ));
    }, []);

    return (
        <Switch>
            <Route exact path="/forgot-password">
                <ForgotPassword />
            </Route>

            <Route exact path="/sign-in">
                <SignIn />
            </Route>

            {routes}

            <Route exact path="/">
                <AuthenticatedUserProvider>
                    <InitialRoute />
                </AuthenticatedUserProvider>
            </Route>

            <Route path="*">
                <AuthenticatedUserProvider>
                    <Page breadcrumbs={{ name: 'Not found' }}>Page not found</Page>
                </AuthenticatedUserProvider>
            </Route>
        </Switch>
    );
};

export default Routes;
