import {
    ComponentsProvider,
    FullScreenLoader,
    Notification,
    NotificationSeverity,
} from '@get-e/react-components';
import React, { useState, FunctionComponent } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
import CenterContainer from './components/CenterContainer';
import Retry from './components/Retry';
import { LocaleContext } from './context/LocaleContext';
import { NotificationContext, Severity } from './context/NotificationContext';
import CurrentUserProvider from './CurrentUserProvider';
import ErrorBoundary from './ErrorBoundary';
import useEffectAsync from './helpers/useEffectAsync';
import useLoaderDebounce from './helpers/useLoaderDebounce';
import history from './history';
import { setLocale as setI18nLocale } from './i18n';
import Routes from './Routes';

const App: FunctionComponent = () => {
    const [localeLoaded, setLocaleLoaded] = useState(false);

    const [notification, setNotification] = useState<{
        message: string;
        color: NotificationSeverity;
    } | null>(null);

    useEffectAsync(async () => {
        await setI18nLocale('en-GB');
        setLocaleLoaded(true);
    }, []);

    const showLoader = useLoaderDebounce(!localeLoaded);

    if (!localeLoaded) {
        return showLoader ? <FullScreenLoader /> : null;
    }

    const showNotification = (message: string, severity?: Severity): void => {
        const color = (() => {
            switch (severity) {
                case Severity.Info:
                case undefined:
                    return 'info';
                case Severity.Error:
                    return 'error';
                default:
                    throw new Error('Unsupported severity');
            }
        })();

        setNotification({
            message,
            color,
        });
    };

    const notificationElement = (() => {
        if (notification === null) {
            return null;
        }

        return (
            <Notification
                severity={notification.color}
                onClose={() => setNotification(null)}
            >
                {notification.message}
            </Notification>
        );
    })();

    const queryClient = new QueryClient();

    return (
        <ErrorBoundary
            error={
                <CenterContainer>
                    {/* eslint-disable-next-line no-console */}
                    <Retry onRetry={() => console.log('Retrying')} />
                </CenterContainer>
            }
        >
            <QueryClientProvider client={queryClient}>
                <LocaleContext.Provider value={{ locale: 'en-GB' }}>
                    <Router history={history}>
                        <ComponentsProvider>
                            <NotificationContext.Provider value={{ showNotification }}>
                                {notificationElement}
                                <CurrentUserProvider>
                                    <Routes />
                                </CurrentUserProvider>
                            </NotificationContext.Provider>
                        </ComponentsProvider>
                    </Router>
                </LocaleContext.Provider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

export default App;
