/* eslint-disable new-cap */
import { FunctionComponent } from 'react';
import { matchPath } from 'react-router-dom';
import { caterersPage } from './pages/Caterers';
import { dailyPage } from './pages/Daily';
import { forecastPage } from './pages/Forecast';
import { reportsPage } from './pages/Reports';
export interface BreadcrumbHeadNode {
    name: string | JSX.Element;
    previous?: BreadcrumbNode;
}

export interface BreadcrumbNode {
    name: string | JSX.Element;
    path: string;
    previous?: BreadcrumbNode;
}

function getParams<T extends string>(path: string): { [param in T]?: string } {
    const match = matchPath<{ [param in T]?: string }>(window.location.pathname, {
        path,
        exact: true,
        strict: true,
    });

    if (!match) {
        throw new Error('Not a match');
    }

    return match.params;
}

export interface PageDefinition {
    path: string;
    Component: FunctionComponent<{ getParam: (key: string) => string }>;
}

const pages: PageDefinition[] = [forecastPage, dailyPage, reportsPage, caterersPage];

export default function createRoutes(): Array<{
    path: string;
    Component: FunctionComponent;
}> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return pages.map(({ path, Component }) => ({
        path,
        Component: (): ReturnType<typeof Component> =>
            Component({
                getParam(handle) {
                    const paramValue = getParams(path)[handle];

                    if (typeof paramValue !== 'string') {
                        throw new Error(`Path does not contain parameter "${handle}"`);
                    }

                    return paramValue;
                },
            }),
    }));
}
