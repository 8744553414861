import hasProperty from './hasProperty';

function getNestedProp(object: unknown, ...properties: string[]): unknown {
    let currentValue = object;

    for (const property of properties) {
        if (!property || !hasProperty(currentValue, property)) {
            return undefined;
        }

        currentValue = currentValue[property];
    }

    return currentValue;
}

const nestedProp = getNestedProp;

export default nestedProp;
