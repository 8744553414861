import {
    DateField,
    LoadingPage,
    Select,
    SelectOption,
    TableBody,
    TableContainer,
    TableHead,
    TableHeader,
} from '@get-e/react-components';
import { Grid, Table, TableCell, TableRow } from '@material-ui/core';
import { addDays, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import CenterContainer from '../../components/CenterContainer';
import Retry from '../../components/Retry';
import getValue from '../../helpers/getValue';
import report from '../../helpers/report';
import apiClient from '../../services/api';
import OrderRow from './OrderRow';
import { DailyResponse, DailyResponseFlight } from './responeTypes';
import { EmployeeDuty, DailyFlightMealOrder } from './types';

export interface BaseFilter {
    iata: string;
}

const getEmployeeDutyEnum = (employeeDuty: string): EmployeeDuty => {
    switch (employeeDuty) {
        case 'CP':
            return EmployeeDuty.Captain;
        case 'FO':
            return EmployeeDuty.FirstOfficer;
        case 'CS':
            return EmployeeDuty.CabinSupervisor;
        case 'JCC':
            return EmployeeDuty.CabinCrew;
        default:
            throw new Error(`Unhandled Crew type ${employeeDuty}`);
    }
};

// eslint-disable-next-line arrow-body-style
const formatFlight = (flight: DailyResponseFlight): DailyFlightMealOrder => {
    return {
        flightNumber: flight.flightNumber,
        route: {
            arrival: { iata: flight.route.arrival.iata },
            departure: { iata: flight.route.departure.iata },
        },
        departure: flight.departure,
        arrival: flight.arrival,
        planeType: flight.planeType,
        meals: flight.meals.map(meal => ({
            mealType: meal.mealType,
            employeeDuty: getEmployeeDutyEnum(meal.employeeDuty),
            employeeCode: meal.employeeCode,
            amount: meal.amount,
        })),
    };
};

const setUTCDate = (date: Date): Date => new Date(date.setUTCHours(4, 0, 0, 0));

const fetchMealOrders = async (data: {
    startDate: Date;
    endDate: Date;
    bases: string[];
}): Promise<DailyResponse> => {
    const utcStart = zonedTimeToUtc(data.startDate, 'UTC');
    const utcEnd = zonedTimeToUtc(data.endDate, 'UTC');
    const startDate = setUTCDate(utcStart).toISOString();
    const endDate = setUTCDate(utcEnd).toISOString();

    const { data: response } = await apiClient.post<DailyResponse>('api/latest-meals', {
        startDate,
        endDate,
        bases: data.bases,
    });

    return response;
};

const Content: FunctionComponent = () => {
    const startDate = new Date();
    const [date, setDate] = useState(startDate);
    const endDate = addDays(date, 1);
    const [base, setBase] = useState('');
    const [flights, setFlights] = useState<DailyFlightMealOrder[]>([]);
    const [error, setError] = useState(false);
    const [baseError, setBaseError] = useState(false);

    const {
        isLoading: loadingBases,
        data: bases = [],
        refetch: refetchBases,
    } = useQuery(
        'getBases',
        () => apiClient.get<BaseFilter[]>('api/bases').then(response => response.data),
        {
            refetchOnWindowFocus: false,
            onSuccess: result => {
                if (!result[0]) {
                    throw new Error('No bases found');
                }

                setBase(result[0].iata);
            },
            onError: (baseErrors: Error) => {
                report(baseErrors);
                setBaseError(true);
            },
        },
    );

    const { mutate: fetchMealsMutation, isLoading: loadingMeals } = useMutation(
        fetchMealOrders,
        {
            onSuccess: data => {
                if (!data[base]) {
                    setFlights([]);
                }

                const responseFlights = data[base]?.flights ?? [];

                const formattedFlights = responseFlights.map(flight =>
                    formatFlight(flight),
                );

                setFlights(formattedFlights);
            },
            onError: (mealsError: Error) => {
                report(mealsError);
                setError(true);
            },
        },
    );

    useEffect(() => {
        if (base.length) {
            fetchMealsMutation({ startDate: date, endDate, bases: [base] });
        }
    }, [base, date]);

    const selectedBase = getValue(() => {
        const foundBase = bases.find(db => db.iata === base);

        if (!foundBase) {
            return '';
        }

        return `- ${foundBase.iata}`;
    });

    if (error) {
        return (
            <CenterContainer>
                <Retry
                    loading={loadingMeals}
                    onRetry={() => {
                        setError(false);
                        fetchMealsMutation({ startDate, endDate, bases: [base] });
                    }}
                />
            </CenterContainer>
        );
    }

    if (baseError) {
        return (
            <CenterContainer>
                <Retry
                    loading={loadingBases}
                    onRetry={() => {
                        setBaseError(false);
                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        refetchBases();
                    }}
                />
            </CenterContainer>
        );
    }

    if (loadingBases || (loadingMeals && !flights.length)) {
        return <LoadingPage />;
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={8}></Grid>
                <Grid container item xs={4}>
                    <Grid item xs={6}>
                        <Select
                            onChange={newBase => {
                                setBase(newBase);
                            }}
                            value={base}
                            label="Base"
                        >
                            {bases.map(dbase => (
                                <SelectOption key={dbase.iata} value={dbase.iata}>
                                    {dbase.iata}
                                </SelectOption>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <DateField
                            value={date}
                            format="dd MMMM, yyyy"
                            label="Date"
                            minimum={startOfDay(startDate)}
                            disableClear
                            onChange={newDate => {
                                if (newDate === null) {
                                    setDate(new Date());
                                    return;
                                }

                                setDate(newDate);
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <TableContainer noFooter>
                <TableHeader title={`Crew meal confirmation report ${selectedBase}`} />
                <Table>
                    <TableHead loading={loadingBases || loadingMeals} columns={7}>
                        <TableRow>
                            <TableCell>Flight</TableCell>
                            <TableCell>Route</TableCell>
                            <TableCell>Departure</TableCell>
                            <TableCell>Arrival</TableCell>
                            <TableCell>Plane type</TableCell>
                            <TableCell>Meals</TableCell>
                            <TableCell>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody loading={loadingBases || loadingMeals}>
                        {flights.length > 0 ? (
                            flights.map((row: DailyFlightMealOrder, index: number) => (
                                <OrderRow key={index} row={row} />
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7}>
                                    <strong>No meals available yet</strong> <br /> There
                                    are no meals expected for this day. Please check again
                                    later or contact support.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Content;
