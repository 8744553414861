import {
    Heading,
    LoadingPage,
    PrimaryButton,
    Select,
    SelectOption,
} from '@get-e/react-components';
import { Grid, makeStyles } from '@material-ui/core';
import { addMonths, format, formatISO, startOfMonth } from 'date-fns';
import React, { FunctionComponent, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import downloadBlob from '../../helpers/downloadBlob';
import getValue from '../../helpers/getValue';
import report from '../../helpers/report';
import apiClient from '../../services/api';

interface BaseCatererGroupResponse {
    id: number;
    name: string;
    excelFormat: string;
}

const useStyles = makeStyles(() => ({
    exportButton: { marginBottom: '1.2rem' },
    forecastHeading: { marginBottom: '0.8rem' },
    forecastText: { marginBottom: '1rem' },
    selectContainer: {
        width: '100%',
        maxWidth: '300px',
    },
}));

const nextMonth = addMonths(new Date(), 1);

const fetchExport = async (group: BaseCatererGroupResponse): Promise<unknown> => {
    const now = new Date();
    const startDate = formatISO(startOfMonth(addMonths(now, 1)));

    const data = {
        groupId: group.id.toString(),
        startDate,
    };

    const response = await apiClient.post('api/forecast-excel', data, {
        responseType: 'blob',
    });

    const headerLine = response.headers['content-disposition'];

    if (!headerLine) {
        throw new Error('Filename for download not set in back-end');
    }

    const filename = headerLine.substring(
        headerLine.indexOf('"') + 1,
        headerLine.lastIndexOf('"')
    );

    downloadBlob(
        response.data,
        filename
    );

    return response;
};

const Content: FunctionComponent = () => {
    const [group, setGroup] = useState<number | null>(null);
    const { showNotification } = useNotificationContext();
    const classes = useStyles();

    const { isLoading, data: groups = [] } = useQuery(
        'getBaseCatererGroups',
        () =>
            apiClient
                .get<BaseCatererGroupResponse[]>('api/base-caterer-groups')
                .then(response => response.data),
        {
            refetchOnWindowFocus: false,
            onSuccess: result => {
                if (!result[0]) {
                    throw new Error('No base caterer groups found');
                }

                setGroup(result[0].id);
            },
            onError: (baseError: Error) => {
                report(baseError);
                showNotification(
                    'Something went wrong while fetching base caterer groups',
                    Severity.Error,
                );
            },
        },
    );

    const { mutate: fetchExportMutation, isLoading: loadingExport } = useMutation(
        fetchExport,
        {
            onError: (exportError: Error) => {
                showNotification(
                    'Something went wrong while exporting the excel',
                    Severity.Error,
                );
                report(exportError);
            },
        },
    );

    if (isLoading) {
        return <LoadingPage />;
    }

    return (
        <div>
            <div className={classes.forecastHeading}>
                <Heading level={2}>Create forecast</Heading>
            </div>
            <div className={classes.forecastText}>
                <strong>Forecast timespan:</strong> {format(nextMonth, 'MMMM - yyyy')}
            </div>
            <div className={classes.forecastText}>
                <strong>Forecast data:</strong> Generated real time from current roster
                data per download.
            </div>
            <Grid container>
                <div className={classes.selectContainer}>
                    <Select
                        onChange={newGroup => {
                            setGroup(newGroup);
                        }}
                        value={group}
                        label="Group"
                    >
                        {groups.map((mgroup, index) => (
                            <SelectOption value={mgroup.id} key={index}>
                                {mgroup.name}
                            </SelectOption>
                        ))}
                    </Select>
                </div>
            </Grid>
            <PrimaryButton
                className={classes.exportButton}
                disabled={group === 0}
                loading={loadingExport}
                onClick={() =>
                    fetchExportMutation(
                        getValue(() => {
                            const foundGroup = groups.find(gr => gr.id === group);

                            if (!foundGroup) {
                                throw new Error('Could not find group');
                            }

                            return foundGroup;
                        }),
                    )
                }
            >
                Download
            </PrimaryButton>
        </div>
    );
};

export default Content;
