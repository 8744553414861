import React, { FunctionComponent } from 'react';
import { AuthenticatedUserContext } from './context/AuthenticatedUserContext';
import { useCurrentUserContext } from './context/CurrentUserContext';

const AuthenticatedUserProvider: FunctionComponent = ({ children }) => {
    const { refreshUser, user, clearUser } = useCurrentUserContext();

    if (!user) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refreshUser();
        return null;
    }

    return (
        <AuthenticatedUserContext.Provider
            value={{
                user,
                refreshUser,
                clearUser,
            }}
        >
            {children}
        </AuthenticatedUserContext.Provider>
    );
};

export default AuthenticatedUserProvider;
