import { Link } from '@get-e/react-components';
import {
    Hidden,
    Breadcrumbs as MaterialBreadcrumbs,
    Typography,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { BreadcrumbHeadNode } from '../createRoutes';

const Breadcrumbs: FunctionComponent<{ node: BreadcrumbHeadNode }> = ({
    node: headNode,
}) => {
    const elements = [
        <Typography color="textPrimary" key={0}>
            {headNode.name}
        </Typography>,
    ];

    let currentNode = headNode.previous;

    while (currentNode) {
        elements.unshift(
            <Link to={currentNode.path} key={currentNode.path}>
                {currentNode.name}
            </Link>,
        );

        currentNode = currentNode.previous;
    }

    return (
        <Hidden xsDown>
            <MaterialBreadcrumbs aria-label="breadcrumb">{elements}</MaterialBreadcrumbs>
        </Hidden>
    );
};

export default Breadcrumbs;
