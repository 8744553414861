export interface MealOrder {
    mealType: string;
    employeeDuty: EmployeeDuty;
    employeeCode: string;
    amount: number;
}
export interface DailyFlightMealOrder {
    flightNumber: string;
    route: {
        arrival: { iata: string };
        departure: { iata: string };
    };
    departure: string;
    arrival: string;
    planeType: string;
    meals: MealOrder[];
}

export enum EmployeeDuty {
    Captain,
    FirstOfficer,
    CabinSupervisor,
    CabinCrew,
}
