import {
    Alert,
    Heading,
    LoadingPage,
    PrimaryButton,
    Select,
    SelectOption,
} from '@get-e/react-components';
import { Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Retry from '../../components/Retry';
import downloadBlob from '../../helpers/downloadBlob';
import getValue from '../../helpers/getValue';
import hasProperty from '../../helpers/hasProperty';
import isArray from '../../helpers/isArray';
import nestedProp from '../../helpers/nestedProp';
import report from '../../helpers/report';
import apiClient from '../../services/api';

interface Report {
    id: string;
    month: number;
    year: number;
}

const useStyles = makeStyles(() => ({
    exportButton: { marginBottom: '1.2rem' },
    reportHeading: { marginBottom: '0.8rem' },
    reportText: { marginBottom: '1rem' },
    selectContainer: {
        width: '100%',
        maxWidth: '300px',
    },
    alertContainer: { marginBottom: '1rem' },
}));

const downloadReport = async (selectedReport: Report): Promise<unknown> => {
    const data = {
        year: selectedReport.year,
        month: selectedReport.month,
    };

    const response = await apiClient.post('api/report-excel', data, {
        responseType: 'blob',
    });

    downloadBlob(
        response.data,
        `report-${selectedReport.year}-${selectedReport.month}.xlsx`
    );

    return response;
};

const getReports = async (): Promise<Report[]> => {
    const reports = await apiClient.get<unknown>('api/reports').then(response => {
        const responseReports = nestedProp(
            response,
            'data',
            'data',
            'reports',
        );

        if (!isArray(responseReports)) {
            throw new Error('Response did not contain reports array');
        }

        return responseReports.map(value => {
            if (
                !hasProperty(value, 'year') ||
                typeof value.year !== 'number' ||
                !hasProperty(value, 'month') ||
                typeof value.month !== 'number'
            ) {
                throw new Error('API returned an invalid report');
            }

            return {
                id: `${value.year}-${value.month}`,
                year: value.year,
                month: value.month,
            };
        });
    });

    return reports;
};

const Content: FunctionComponent = () => {
    const [selectedReport, setSelectedReport] = useState<string | null>(null);
    const [reportsError, setReportsError] = useState(false);
    const [reportDownloadError, setReportDownloadError] = useState(false);
    const classes = useStyles();

    const { isLoading, data: reports = [] } = useQuery('getReports', getReports, {
        refetchOnWindowFocus: false,
        onSuccess: result => {
            if (result[0]) {
                setSelectedReport(result[0].id);
            }
        },
        onError: (repError: Error) => {
            setReportsError(true);
            report(repError);
        },
    });

    const { mutate: downloadReportMutation, isLoading: loadingReport } = useMutation(
        downloadReport,
        {
            onError: (expError: Error) => {
                setReportDownloadError(true);
                report(expError);
            },
        },
    );

    if (isLoading && !reportsError) {
        return <LoadingPage />;
    }

    if (reportsError) {
        return (
            <Retry
                loading={isLoading}
                onRetry={() => {
                    setReportsError(false);
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    getReports();
                }}
            />
        );
    }

    return (
        <div>
            <div className={classes.reportHeading}>
                <Heading level={2}>Create report</Heading>
            </div>
            <div className={classes.reportText}>
                <p>
                    Daily orders and monthly forecasts are saved for 5 years.
                    After each month, you can export this data below.
                </p>
            </div>

            {reports.length > 0
                ? (
                    <Grid container>
                        <div className={classes.selectContainer}>
                            <Select
                                onChange={newReport => {
                                    setSelectedReport(newReport);
                                }}
                                value={selectedReport}
                                label="Month"
                            >
                                {reports.map((sreport, index) => (
                                    <SelectOption value={sreport.id} key={index}>
                                        {`Report ${sreport.year}-${sreport.month}`}
                                    </SelectOption>
                                ))}
                            </Select>

                        </div>
                    </Grid>
                )
                : (
                    <Grid container>
                        <Alert className={classes.alertContainer} severity="warning">
                            No reports available yet.
                        </Alert>
                    </Grid>
                )
            }

            <PrimaryButton
                className={classes.exportButton}
                disabled={selectedReport === null}
                loading={loadingReport}
                onClick={() => {
                    setReportDownloadError(false);

                    downloadReportMutation(
                        getValue(() => {
                            const foundReport = reports.find(
                                re => re.id === selectedReport,
                            );

                            if (!foundReport) {
                                throw new Error('Could not find correct report');
                            }

                            return foundReport;
                        }),
                    );
                }}
            >
                Download
            </PrimaryButton>

            {reportDownloadError ? (
                <Alert severity="error">
                    Something went wrong while downloading the report.
                </Alert>
            ) : null}
        </div>
    );
};

export default Content;
