import { createContext, useContext } from 'react';

export interface AuthenticatedUserContextValue {
    user: {
        id: string;
        name: string;
        email: string;
    };
    refreshUser: () => Promise<void>;
    clearUser: () => void;
}

export const AuthenticatedUserContext =
    createContext<AuthenticatedUserContextValue | null>(null);

export const useAuthenticatedUserContext = (): AuthenticatedUserContextValue => {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    if (authenticatedUserContext === null) {
        throw new Error('AuthenticatedUserContext should not be null');
    }

    return authenticatedUserContext;
};
