import { MenuListItem } from '@get-e/react-components';
import { List } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import React, { FunctionComponent } from 'react';
import { matchPath } from 'react-router-dom';

function onPage(path: string): boolean {
    return Boolean(
        matchPath(window.location.pathname, {
            path,
            exact: true,
            strict: true,
        }),
    );
}

const NavigationList: FunctionComponent = () => (
    <List role="menu">
        <MenuListItem
            icon={<RoomServiceIcon fontSize="small" />}
            label="Daily orders"
            href="/daily"
            selected={onPage('/daily')}
        />
        <MenuListItem
            icon={<DynamicFeedIcon fontSize="small" />}
            label="Forecast"
            href="/forecast"
            selected={onPage('/forecast')}
        />
        <MenuListItem
            icon={<BarChartIcon fontSize="small" />}
            label="Reports"
            href="/reports"
            selected={onPage('/reports')}
        />
    </List>
);

export default NavigationList;
