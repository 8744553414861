import { MenuButton, MenuButtonItem, userButtonIcon } from '@get-e/react-components';
import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthenticatedUserContext } from '../../context/AuthenticatedUserContext';
import { Severity, useNotificationContext } from '../../context/NotificationContext';
import report from '../../helpers/report';
import apiClient from '../../services/api';

const useStyles = makeStyles(theme => ({
    button: { padding: '.5rem 1rem !important' },
    icon: { [theme.breakpoints.up('sm')]: { margin: '0 0 0 .5em' } },
}));

const UserMenuButton: FunctionComponent<{
    className?: string;
}> = ({ className }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [signingOut, setSigningOut] = useState(false);
    const history = useHistory();
    const { user, clearUser } = useAuthenticatedUserContext();
    const classes = useStyles();
    const { showNotification } = useNotificationContext();

    const signOutFailed = (): void => {
        showNotification(
            'Sign out failed. Please try again or contact us.',
            Severity.Error,
        );
        setSigningOut(false);
        setMenuOpen(false);
    };

    const signOut = async (): Promise<void> => {
        setSigningOut(true);
        await apiClient
            .post('api/logout')
            .then(() => {
                clearUser();
                history.push('/sign-in');
            })
            .catch(error => {
                report(error);
                signOutFailed();
            });
    };

    return (
        <span className={className}>
            <MenuButton
                className={classes.button}
                open={menuOpen}
                onClick={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                menuItems={[
                    <MenuButtonItem
                        key="sign-out"
                        loading={signingOut}
                        onClick={signOut}
                        dangerous
                    >
                        Sign out
                    </MenuButtonItem>,
                ]}
            >
                {user.name}

                <img src={userButtonIcon} alt="User icon" className={classes.icon} />
            </MenuButton>
        </span>
    );
};

export default UserMenuButton;
