import { LoadingPage } from '@get-e/react-components';
import axios, { AxiosError } from 'axios';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CenterContainer from './components/CenterContainer';
import Retry from './components/Retry';
import { CurrentUserContext } from './context/CurrentUserContext';
import apiClient from './services/api';

interface User {
    id: string;
    name: string;
    email: string;
}

const CurrentUserProvider: FunctionComponent = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const history = useHistory();

    const getUser = async (): Promise<void> => {
        setLoading(true);
        setError(false);

        await apiClient
            .get<User>('api/user')
            .then(response => {
                setUser({
                    id: response.data.id,
                    name: response.data.name,
                    email: response.data.email,
                });
                setLoading(false);
            })
            .catch((catchError: Error | AxiosError) => {
                if (
                    axios.isAxiosError(catchError) &&
                    catchError.response?.status === 401
                ) {
                    setUser(null);
                    setLoading(false);
                    history.push('/sign-in');
                    return;
                }

                setError(true);
                setLoading(false);
            });
    };

    if (loading) {
        return <LoadingPage />;
    }

    if (error) {
        return (
            <CenterContainer>
                <Retry loading={loading} onRetry={() => getUser()} />
            </CenterContainer>
        );
    }

    return (
        <CurrentUserContext.Provider
            value={{
                user,
                refreshUser: () => getUser(),
                clearUser: () => setUser(null),
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserProvider;
