import { createContext, useContext } from 'react';

export interface CurrentUserContextValue {
    user: null | {
        id: string;
        name: string;
        email: string;
    };
    refreshUser: () => Promise<void>;
    clearUser: () => void;
}

export const CurrentUserContext = createContext<CurrentUserContextValue | null>(null);

export const useCurrentUserContext = (): CurrentUserContextValue => {
    const currentUserContext = useContext(CurrentUserContext);

    if (currentUserContext === null) {
        throw new Error('CurrentUserContext should not be null');
    }

    return currentUserContext;
};
