import { Heading, PrimaryButton } from '@get-e/react-components';
import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(theme => ({
    retryButton: { margin: theme.spacing(2, 0) },
    container: { margin: theme.spacing(2) },
}));

const Retry: FunctionComponent<{
    onRetry: () => void;
    loading?: boolean;
}> = ({ onRetry, loading }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Heading level={2}>Unexpected error</Heading>
            <div>
                Sorry, something went wrong on our end. Please refresh the page or contact
                our 24/7 support team
            </div>
            <PrimaryButton
                className={classes.retryButton}
                onClick={onRetry}
                loading={loading}
            >
                Retry
            </PrimaryButton>
        </div>
    );
};

export default Retry;
