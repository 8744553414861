import React from 'react';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import Content from './Content';

export const caterersPage: PageDefinition = {
    path: '/caterers',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'Caterers' }}>
                <Content />
            </Page>
        );
    },
};
