import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

const Refresh: FunctionComponent = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.container}>{children}</div>;
};

export default Refresh;
