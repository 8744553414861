import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import history from '../history';
import getEnv from './getEnv';
import getValue from './getValue';
import { Environment, getCurrentEnvironment } from './onEnvironment';

export function initSentry(): void {
    Sentry.init({
        dsn: getEnv('SENTRY_DSN'),
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
        ],
        tracesSampleRate: 1.0,
        environment: getValue(() => {
            const environment = getCurrentEnvironment();

            switch (environment) {
                case Environment.Production:
                    return 'production';
                case Environment.Staging:
                    return 'staging';
                case Environment.Local:
                    return 'local';

                default:
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    throw new Error(`Unhandled environment "${environment}"`);
            }
        }),
    });
}

export type ErrorNumber = string;

// TODO: Set/clear context somewhere

export function setUserContext(context: UserContext): void {
    Sentry.setUser({
        id: context.userId.toString(),
        email: context.emailAddress,
    });
}

export function clearUserContext(): void {
    Sentry.setUser(null);
}

export default function report(error: Error): ErrorNumber {
    const errorNumber = Sentry.captureException(error);

    // eslint-disable-next-line no-console
    console.error(`Sentry error ${errorNumber}:`, error);

    return errorNumber;
}

export interface UserContext {
    userId: number;
    emailAddress: string;
}
